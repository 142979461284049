@import "./custom-variables";
@import "../app";
@import "./custom-functions";
@import "./custom-mixins";
@import "./reset";
@import "./fonts";

/*Each loop for generating font size class(e.g. font-68, font-24)*/
@each $fonts in $font-sizes-map {
    .font-#{$fonts} {
        @include rfs($fonts);
    }
}

/*Each loop for generating heading tags with font size(e.g. h1, h2, h3)*/
@each $size in map-get(map-get($heading-tag-font-map, "font"), "size") {
    $index: index(
        map-get(map-get($heading-tag-font-map, "font"), "size"),
        $size
    );
    h#{$index} {
        @include rfs($size);
        font-family: $heading-font;
        color: $secondary;
        text-transform: capitalize;
    }
}

/*Each loop for generating class as per the font family(e.g. kanit-light, kanit-bold, cera-bold)*/
@each $family in $fontsFamily {
    $lenght: str-length($family);
    @if (str-index($family, "CeraPro-")) {
        $font-name: str-slice($family, 9, $lenght);
        .cera-#{to-lower-case(#{$font-name})} {
            font-family: $family;
        }
    } @else if (str-index($family, "Mukta-")) {
        $font-name: str-slice($family, 7, $lenght);
        .mukta-#{to-lower-case(#{$font-name})} {
            font-family: $family;
        }
    } @else if (str-index($family, "Futura-")) {
        $font-name: str-slice($family, 8, $lenght);
        .futura-#{to-lower-case(#{$font-name})} {
            font-family: $family;
        }
    } @else {
        $font-name: str-slice($family, 9, $lenght);
        .poppins-#{to-lower-case(#{$font-name})} {
            font-family: $family;
        }
    }
}
/*Generates class for borders (e.g. border-top-1, border-bottom-1, border-right-1, border-left-1)*/
@each $pos in $direction {
    @for $i from 1 through 3 {
        .border-#{$pos}-#{$i} {
            border-#{$pos}: #{$i}px solid #c5c5c5;
        }
    }
}
/*Genrate Line Height Class (e.g. lh-11, lh-12, lh-13, lh-14 )*/
@for $i from 1 through 9 {
    .lh-1#{$i} {
        line-height: 1+ (0.1 * $i);
    }
}

/*Gnerate Gap class for flex items*/
$sizes: 2, 4, 6, 8;
@each $size in $sizes {
    .gap-#{$size} {
        gap: #{$size}px;
    }
}
/****==CSS Area Start==****/

.icon-clear {
    display: inline-block;
    height: 22px;
    width: 22px;
    border-radius: 100%;
    background-color: $btn-primary;
    position: relative;
    z-index: 2;
    &::before,
    &::after {
        content: "";
        display: block;
        height: 2px;
        width: 12px;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
    }
    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}
/*Header Navigation CSS Start*/
header {
    z-index: 1021 !important;
}
.header-top {
    .header-top-height {
        padding: 7px 0;
        @extend .lh-12;
        @media (min-width: 1600px) {
            padding: 10px 0;
        }
        .nav-item:not(.social-nav-items) {
            .nav-link {
                &:hover {
                    color: $secondary !important;
                }
            }
        }
    }
    .header-top-items {
        * {
            @include rfs(14);
            @media (min-width: 1600px) {
                @include rfs(16);
            }
        }
        svg {
            height: 20px;
            width: 20px;
            @media (min-width: 1600px) {
                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }
        a {
            &:hover {
                color: $secondary !important;
            }
        }
    }
}
header {
    .navbar-brand {
        /*&.logo-v-center {
            @include media-breakpoint-up(lg){
                position: relative; top: 14px; z-index: 999;
            }
        }*/
        img {
            max-width: 100px;
        }

        @media (min-width: 1600px) {
            img {
                max-width: 125px;
            }
        }
        @include media-breakpoint-down(md) {
            img {
                max-width: 80px;
            }
        }
        @include media-breakpoint-down(sm) {
            img {
                max-width: 70px;
            }
        }
    }
    .navigationItemsList .nav-item {
        margin-right: 40px;
    }
    .nav-item {
        @include media-breakpoint-down(md) {
            &:not(:last-child) {
                border-bottom: 1px solid #eeeeee;
            }
            &:first-child {
                border-top: 1px solid #eeeeee;
                margin-top: 10px;
            }
            &:last-child,
            &.social-nav-items {
                padding: 5px $num0;
            }
        }
        &.nav-item-contact {
            padding: $num0;
            margin-bottom: -0.25rem;
            .nav-item-contact-ul-first {
                li {
                    width: 100%;
                }
            }
            a {
                color: $btn-primary !important;
                &:hover {
                    color: $secondary !important;
                    svg {
                        fill: $secondary !important;
                    }
                }
            }
            svg {
                fill: $btn-primary;
            }
        }
        &.active {
            .nav-link {
                color: $secondary;
            }
        }
        &.social-nav-items {
            justify-content: center;
            .top-social-icon {
                padding: 5px;
                &:not(:last-child) {
                    margin-right: 0.5rem;
                }
                svg {
                    width: 20px;
                    height: 20px;
                }
                @include media-breakpoint-up(lg) {
                    svg {
                        fill: $white;
                        &:hover {
                            fill: $secondary;
                        }
                    }
                }
            }
        }
    }
    .nav-link {
        @extend .futura-heavy, .lh-12;
        font-size: rfs-value(15px);
        color: $btn-primary;

        @media (min-width: 1600px) {
            font-size: rfs-value(17px);
        }
        @include media-breakpoint-down(sm) {
            font-size: rfs-value(14px);
        }
        &:hover,
        &.active {
            color: $secondary;
        }
    }
    .navbar-toggler {
        padding: 0.625rem 0.9375rem;
        &:after,
        &:before,
        & > span {
            content: "";
            display: block;
            height: 3px;
            width: 26px;
            border-radius: 50px;
            background-color: $primary;
            margin-bottom: 5px;
            @include transition-mixin(all, 0.4, ease);
        }
        &:after {
            margin: $num0;
        }
        &:not(.collapsed) {
            span {
                visibility: hidden;
                opacity: 0;
            }
            &:before {
                transform: rotate(45deg) translateY(6px) translateX(5px);
                -webkit-transform: rotate(45deg) translateY(6px) translateX(5px);
            }
            &:after {
                transform: rotate(-45deg) translateY(-6px) translateX(6px);
                -webkit-transform: rotate(-45deg) translateY(-6px)
                    translateX(6px);
            }
        }
    }
    .navbar {
        .dropdown-menu {
            left: auto;
            .nav-link {
                @media (min-width: 1600px) {
                    @include rfs(15);
                    line-height: 1.4;
                }
                @extend .font-14, .lh-12;
                &:hover,
                &:active,
                &.active {
                    color: $secondary;
                }
            }
        }
    }

    .header-sub-nav {
        //background-color: lighten($primary, 18%);
        border-top: 1px solid #eeeeee;
        .nav-item {
            border: $num0;
            margin: $num0;
            padding: $num0 !important;
            .nav-link {
                border-bottom: 2px solid transparent;
                @include media-breakpoint-down(xxl) {
                    padding: 0.25rem 1rem;
                }
                //color: darken($primary, 15%);
                @media (max-width: 480px) {
                    padding: 0.25rem 0.625rem;
                    text-align: center;
                }

                @media (max-width: 365px) {
                    padding: 0.25rem 0.5rem;
                    @include rfs(13px);
                }
            }

            &:hover,
            &:focus,
            &.active {
                .nav-link {
                    //color: lighten($black, 25%);
                    border-bottom-color: $secondary;
                }
            }
        }
    }
    .header-top-height,
    .nav-item-contact {
        &.active {
            .nav-contact-link {
                color: $secondary !important;
            }
        }
    }
}
.userprofile-login {
    &:not(.userprofile-login-btn) {
        @include media-breakpoint-down(lg) {
            position: absolute;
            right: 63px;
            top: 5px;
        }
    }
    &.userprofile-login-btn {
        @include media-breakpoint-down(lg) {
            position: absolute;
            top: 7px;
            right: 70px;
        }
    }
    .nav-login-item {
        .btn.btn-sm,
        #user-dropdown {
            @include media-breakpoint-down(lg) {
                min-height: 36px;
                min-width: 104px;
            }
            @include media-breakpoint-down(md) {
                @include font-size-mixin(12.524);
                min-height: 22px;
                min-width: 90px;
            }
        }
    }
    .userimg {
        img {
            width: 34px;
            height: 34px;
            background-color: $gray;
            object-fit: cover;
            object-position: top center;
        }
    }
    .dropdown-menu {
        top: calc(100% + 5px);
        left: auto;
        right: $num0;
        min-width: 12rem;
        border: 0;
        overflow: hidden;
        border-radius: 0.5rem;
        box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, 0.03),
            0 0.9375rem 1.40625rem rgba(31, 10, 6, 0.03),
            0 0.25rem 0.53125rem rgba(31, 10, 6, 0.05),
            0 0.125rem 0.1875rem rgba(31, 10, 6, 0.03);
        .userimg {
            min-width: 34px;
        }
        &.show {
            animation: fade-in2 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            @keyframes fade-in2 {
                0% {
                    margin-top: -50px;
                    visibility: hidden;
                    opacity: 0;
                }
                100% {
                    margin-top: 0px;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
.dropdown-menu {
    .dropdown-list-item {
        &:hover,
        &.active {
            a {
                color: $secondary;
            }
        }
    }
}
.banner-text {
    *,
    a {
        color: #f4f4f4;
    }
}
.main-banner {
    .banner-text {
        max-width: 740px;
        @include media-breakpoint-down(sm) {
            p {
                @include rfs(0.875rem);
                line-height: 1.2;
            }
            .banner-btn {
                padding-top: 0.03125px !important;
            }
        }
        .banner-btn {
            .btn-lg {
                font-size: rfs-value(1rem);
            }
        }
        @media (max-width: 500px) {
            .banner-btn {
                .btn-lg {
                    line-height: 1.2;
                    font-size: rfs-value(0.875rem);
                    min-width: 131px;
                    min-height: 34px;
                }
            }
        }
    }
}
/*Home Page About Us CSS Start*/
.hm-about-img {
    padding-left: 46px;
    @include media-breakpoint-down(lg) {
        max-width: 700px;
    }
    &::before {
        content: "";
        display: block;
        @extend .pattern;
        position: absolute;
        left: $num0;
        top: 48px;
        width: 231px;
        height: 100%;
        opacity: 0.18;
    }
}
/*Home Page About Us CSS Start*/
.hm-team-wrap {
    &::before {
        content: "";
        display: block;
        @extend .pattern-white;
        position: absolute;
        left: $num0;
        top: $num0;
        width: 100%;
        height: 100%;
        opacity: 0.06;
    }
}
/*Contact Box CSS Start*/
.contact-box {
    @extend .form-bg;
    max-width: 912px;
    padding: 30px 15px;
}
.g-recaptcha-form-group {
    @media (max-width: 400px) {
        .g-recaptcha-box {
            width: 236px;
        }
        .g-recaptcha {
            transform: scale(0.77);
            -webkit-transform: scale(0.77);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            max-width: 100%;
        }
    }
}
.error-page-container {
    min-height: calc(100vh - 237px);
    @include media-breakpoint-down(lg) {
        min-height: calc(100vh - 182px);
    }
    @include media-breakpoint-down(md) {
        min-height: calc(100vh - 155px);
    }
}

/*Front Side Login and Registration Page CSS*/
.split-form-wrapper {
    background-color: #f7f7f7;
    .split-window-right {
        padding: 30px 15px;
        @include media-breakpoint-up(lg) {
            padding: 30px 20px;
        }
        .login-form {
            max-width: 420px;
            margin: 0 auto;
            width: 100%;
        }
        .registration-form {
            @include media-breakpoint-down(sm) {
                max-width: 420px;
                margin: 0 auto;
            }
        }

        &.reg-split-window-right {
            overflow: auto;
            height: auto !important;
            @media (min-width: 1401px) {
                max-height: 942px;
            }
            @media (min-width: 992px) and (max-width: 1401px) {
                max-height: 842px;
            }
            @media (min-width: 768px) and (max-width: 991.98px) {
                max-height: 742px;
            }
        }
    }
    .custom-control-label {
        &::before,
        &::after {
            top: 0.165rem;
        }
    }
}
.container-wrapper {
    @include media-breakpoint-up(lg) {
        margin: $num0 16px;
    }
}
.back-btn-arrow {
    border-bottom: 1px solid transparent;
    &::before {
        content: "";
        display: inline-block;
        border: solid $primary;
        border-width: 7px 0 7px 10px;
        border-top-color: transparent;
        border-bottom-color: transparent;
        position: relative;
        top: 1px;
        transform: rotate(-180deg);
        margin-right: 10px;
    }
    &:hover {
        border-bottom: 1px solid $primary;
        color: $primary;
    }
}
.width-wrapper {
    max-width: 880px;
    margin: 0 auto;
    &.profile-wrapper-width {
        max-width: 1240px;
    }
}
.pg-row {
    &-btn {
        min-height: 24px;
        height: 24px;
        min-width: 24px;
        max-width: 24px;
        display: inline-block;
        border-radius: 4px;
        position: relative;
        &::before,
        &::after {
            content: "";
            display: block;
            background-color: $white;
            height: 1.5px;
            width: 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-add {
        background-color: #23c749;
        top: 44px;
        &::after {
            transform: translate(-50%, -50%) rotate(90deg);
        }
        &.top-0 {
            top: $num0;
        }
    }
    &-remove {
        background-color: #fe0000;
        top: 11px;
        &::after {
            display: none;
        }
        &.top-0 {
            top: $num0;
        }
    }
}

.work-address-row {
    &:not(:last-child) {
        margin-bottom: 1.5rem; //border-bottom: 1px solid #CCCCCC;
    }
}
.pg-traning-row {
    &:not(:last-child) {
        margin-bottom: 1.5rem; //border-bottom: 1px solid #CCCCCC;
    }
    .pg-traning-fields {
        width: calc(100% - 35px);
    }
}
.profile-upload-wrapper {
    @include media-breakpoint-up(lg) {
        max-width: 200px;
    }
}
.cv-upload-wrapper {
    .dropify-wrapper {
        height: 61px;
        .dropify-message {
            span.file-icon {
                font-size: $num0;
                display: none;
            }
            p:not(:last-child) {
                margin: $num0;
            }
        }
        .dropify-preview {
            .dropify-render {
                i {
                    font-size: 45px;
                }
                .dropify-extension {
                    margin-top: 5px;
                    @include rfs(12px);
                }
            }
            .dropify-infos {
                .dropify-infos-inner {
                    p {
                        padding-right: 63px;
                    }
                    p.dropify-infos-message {
                        margin-top: $num0;
                        padding-top: $num0;
                    }
                }
            }
        }
        .dropify-clear {
            top: 50%;
            transform: translateY(-50%);
            @include rfs(10px);
            padding: 2px 8px;
        }
    }
}
.phone-wrap-width {
    @include media-breakpoint-up(lg) {
        max-width: 356px;
        min-width: 356px;
    }
    width: 100%;
}
.country-code-input {
    max-width: 75px;
    width: 100%;
    text-align: center;
    border-top-right-radius: $num0;
    border-bottom-right-radius: $num0;
    border-right: 1px solid $primary !important;
}

/*Physician Finder Search*/
.search-result-wrapper {
    #wrapper {
        .row {
            min-height: 50vh !important;
        }
    }
    .search-result-wrapper-inner-left {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
    }
    .search_result {
        max-height: 540px;
        overflow: auto;
        position: relative;
        z-index: 1;
        border-radius: $num0 $num0 8px 8px;
        .result_unit {
            @extend .word-break;
            &:not(:last-child) {
                border-bottom: 1px solid #dee2e6;
            }
        }
        .result-unit-checkbox {
            min-width: 24px;
            padding-top: 5px;
            .custom-checkbox {
                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                    .custom-control-label {
                        &::before {
                            background-color: #dddddd !important;
                        }
                    }
                }
                .custom-control-input {
                    z-index: 2;
                }
            }
        }
    }
    .map-container {
        height: 100%;
        @include media-breakpoint-down(md) {
            height: 400px;
        }
    }
    .search-profile-img {
        min-width: 50px;
    }
    .front-user-img {
        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            object-position: top center;
        }
    }
    .search-result-details {
        .more-btn {
            //border-bottom: 2px solid $primary; @include rfs(15); @extend .lh-13;
            @extend .text-capitalize;
        }
    }
    .search-result-select-all {
        min-height: 36px;
        margin-bottom: -1px;
        border-radius: 8px 8px $num0 $num0;
        .btn.create-group-btn {
            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }
    .custom-control-label {
        &::before,
        &::after {
            top: 0.05rem;
            width: 1.125rem;
            height: 1.125rem;
        }
    }
    .custom-checkbox {
        .custom-control-input {
            &:checked ~ .custom-control-label {
                &::after {
                    background-size: 12px;
                }
            }
        }
    }
}

.physician-modal-body {
    .post-graduation-table {
        td {
            &:nth-child(1) {
                min-width: 200px;
                max-width: 200px;
            }
            &:nth-child(2) {
                min-width: 150px;
                max-width: 150px;
            }
            &:nth-child(3) {
                min-width: 150px;
                max-width: 150px;
            }
        }
    }
}
.errorMsg,
.errormessage,
.error-comment {
    @extend .futura-medium, .lh-14;
    small {
        font-size: 80% !important;
    }
}

/*Physician Profile Page on Seach*/
.icon-finger {
    @include background-image("./icon_finger_touch.svg", $no-repeat, $center);
    width: 30px;
    height: 30px;
    display: block;
    background-size: 100%;
}
.search-physician-container {
    .search-physician-top-inner {
        max-width: 920px;
        margin: $num0 auto;
        @extend .word-break;
        .search-physician-name-img {
            flex-basis: 60%;
            @include media-breakpoint-down(sm) {
                flex-basis: 100%;
                width: 100%;
            }
            .search-physician-img {
                max-width: 170px;
                min-width: 170px;
                img {
                    border-radius: 50%;
                    object-position: center;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    border: 1px solid $primary;
                }
            }
            .search-physician-name {
                h2 {
                    @include rfs(2.25rem);
                }
                .btn {
                    @extend .poppins-regular;
                    @include rfs(22);
                    padding: 0.35rem 1.75rem;
                    position: relative;
                    border-radius: 12px;

                    &.show-chatgroup-btn {
                        @include rfs(16px);
                        padding: 0.35rem 0.75rem;
                    }
                }
            }
        }
        .search-physician-contact-info {
            flex-basis: 40%;
            @extend .lh-14;
            @include media-breakpoint-down(sm) {
                flex-basis: 60%;
                width: 60%;
            }
            @include media-breakpoint-down(xs) {
                flex-basis: 100%;
                width: 100%;
            }
            p {
                @extend .lh-16;
                @include rfs(26);
            }
            a {
                @extend .lh-13;
                @include rfs(24);
            }
        }
    }
    .search-physician-bottom {
        .search-physician-details {
            .physician-modal-body .post-graduation-table td {
                &:nth-child(1) {
                    padding-left: $num0;
                }
                @include media-breakpoint-up(md) {
                    &:nth-child(1) {
                        width: 43.3%;
                        min-width: auto;
                        max-width: none;
                        padding-left: $num0;
                    }
                    &:nth-child(2) {
                        width: 31.3%;
                        min-width: auto;
                        max-width: none;
                    }
                    &:nth-child(3) {
                        width: 35.3%;
                        min-width: auto;
                        max-width: none;
                    }
                }
            }
            .work-address-row {
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding-bottom: 10px;
                }
            }
        }
    }
}
:root {
    --offcanvas-width: 350px;
    --header-heigth: 133.42px;
}
/* Off canvas menu */
.cursor-pointer {
    cursor: pointer;
}
#searchPhysicians {
    width: var(--offcanvas-width);
}

#show-list,
#show-map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
}
#show-list.active,
#show-map.active,
#list-view,
#list-view.active,
#show-map img,
#show-list img {
    width: 100%;
    height: 100%;
}
#show-map-btn,
#show-list-btn {
    margin: 0;
    padding: 0;
    font-weight: bolder !important;
    background-color: rgba(255, 255, 255, 0.8);
}
.map-container {
    width: 100%;
    height: 500px;
}
@media (min-width: 975px) {
    body {
        overflow: auto;
    }
    .offcanvas-backdrop {
        display: none;
    }
    #physician-finder {
        position: relative;
        min-height: 90vh;
    }
    #searchPhysicians {
        transform: none;
        visibility: visible !important;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
        .btn-close {
            display: none;
        }
    }
    #open-search-menu {
        display: none;
    }
    #results-wrapper {
        margin-left: var(--offcanvas-width);
    }
}

/*Chat Group Listing*/
.chat-group-listing {
    gap: 5px;
    .chat-group-items {
        background: $primary;
        border-radius: 4px;
        @extend .word-break;
        @include transition-mixin(all, 0.4, ease);
        &:hover {
            box-shadow: $num0 4px 8px rgba(0, 0, 0, 0.12);
        }
        .chat-group-icon {
            min-width: 28px;
            overflow: hidden;
            width: 28px;
            height: 28px;
            background-color: $white;
            border-radius: 4px;
            img {
                max-width: 100%;
                width: 100%;
            }
        }
        .chat-group-name {
            color: $white;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1.2;
            @media (min-width: 1600px) {
                line-height: 1.4;
            }
        }
    }
    &-xl {
        min-height: 174px;

        &.group-listing-items {
            min-height: auto;
        }
        .chat-group-items {
            .chat-group-icon {
                min-width: 80px;
                width: 80px;
                height: 80px;
                overflow: hidden;
                background-color: $white;
                border-radius: 4px;
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
            .chat-group-name {
                max-width: none;
                overflow: visible;
                text-overflow: initial;
                white-space: initial;
                .btn {
                    @include font-family-mixin($futura-heavy);
                    text-transform: capitalize;
                }
            }
        }
    }
    .chat-group-title-icon {
        margin-bottom: 0.75rem;
        span {
            margin-right: 0.5rem;
            margin-bottom: $num0 !important;
        }
        svg {
            min-width: 20px;
            height: 20px;
            width: 20px;
            fill: $white;
            margin-top: 3px;
        }
    }
}
.profile-pic-box-sticky {
    .dropify-wrapper {
        height: 260px;
    }
}
#groupPhysicianContainer {
    .menu-icon {
        height: 25px;
        width: auto;
        object-fit: contain;
        background: none;
    }
    .dropdown-item:hover {
        background: none;
    }
}

/*Chat Room CSS Start*/
.chat-container {
    height: 70vh;
    overflow: hidden;
    .chat-container-listing {
        min-width: 360px;
        border-right: 1px solid darken($white, 5%);
        @include media-breakpoint-up(xxxl) {
            min-width: 360px;
            max-width: 360px;
        }
        @include media-breakpoint-between(xl, xxl) {
            min-width: 340px;
            max-width: 340px;
        }
        @include media-breakpoint-down(lg) {
            min-width: 320px;
            max-width: 320px;
        }
        @include media-breakpoint-down(sm) {
            position: absolute;
            left: -100%;
            z-index: 2;
            transition: left 0.3s ease;
            max-height: 70vh;
            &.show,
            &.chat-container-listing-welcome {
                z-index: 4;
                left: 0;
                width: 100%;
                min-width: auto;
                max-width: none;
            }
        }
        .chat-container-listing-title {
            height: 49px;
            .btn {
                @include rfs(13px);
                &:hover {
                    text-decoration: none !important;
                }
            }
        }
        .chat-container-listing-search {
            padding: 10px $num0;
            border-left: 1px solid lighten($black, 86%);
            .chat-container-listing-search-width {
                width: 95%;
            }
            .form-control {
                padding-left: 42px;
                border-radius: 21px;
                height: 38px;
                &:focus {
                    box-shadow: $num0 $num0 1px 2px darken($primary, 10%);
                }
            }
        }
        .chat-container-users-list {
            min-height: calc(100% - 106px);
            max-height: calc(100% - 106px);
            overflow-y: auto;
            overflow-x: hidden;
            ul {
                li {
                    padding: 0.5rem 1rem;
                    cursor: pointer;
                    @include transition-mixin(all, 0.2, ease);
                    color: $white;

                    &:not(:last-child) {
                        margin-bottom: 0.25rem;
                    }
                    &.active {
                        background-color: darken($primary, 8);
                        color: lighten($black, 98);
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
                    }
                    &:not(.active):hover {
                        background-color: darken($primary, 6);
                        color: $white;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
                    }
                    &.group-list {
                        .chat-container-user-img {
                            svg {
                                background-color: $white;
                                fill: $btn-primary;
                            }
                        }
                    }
                }
            }
            .chat-container-user-img {
                min-width: 30px;
                img {
                    width: 30px;
                    height: 30px;
                    max-width: 100%;
                    background-color: $white;
                    object-fit: cover;
                    object-position: top center;
                }
            }
            .chat-container-user-name {
                .name-title {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 142px;
                    padding-right: 1rem;
                }
            }
        }
    }
    .chat-container-thread {
        background-color: lighten($black, 92.5);
        min-width: $num0;
        @include media-breakpoint-down(sm) {
            position: absolute;
            z-index: 3;
            transition: left 0.3s ease;
            width: 94%;
            max-height: 70vh;
        }

        .chat-container-thread-list {
            &:not(.chat-thread-media-height) {
                min-height: calc(100% - 86px);
                max-height: calc(100% - 86px);
            }
            &.chat-thread-media-height {
                min-height: calc(100% - 124px);
                max-height: calc(100% - 124px);
            }
            overflow-y: auto;
            overflow-x: hidden;

            .msg-row {
                @extend .lh-14;
                margin-bottom: 1rem;
                &:not(:last-child) {
                    margin-bottom: 1.5rem;
                }
                .chat-upload-img {
                    max-width: 150px;
                }
                .chat-upload-video {
                    max-width: 400px;
                    video {
                        max-width: 100%;
                    }
                }
                .recv-msg,
                .sent-msg {
                    .msg-box {
                        a {
                            @extend .futura-heavy;
                            text-decoration: underline;
                            &:hover {
                                color: $primary;
                            }
                        }
                    }
                    p {
                        margin: 0;
                        padding: 0.5rem 0.75rem;
                        display: inline-block;
                        @include rfs(14px);
                        @include font-family-mixin($futura-medium);
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
                    }
                }

                &.recv-msg-row {
                    .recv-img {
                        min-width: 36px;
                        img {
                            max-width: 100%;
                            width: 36px;
                            height: 36px;
                            object-fit: cover;
                            object-position: top center;
                            border-radius: 100%;
                        }
                    }
                    .recv-msg {
                        .msg-box {
                            a {
                                color: lighten($black, 15%);
                            }
                        }
                        .recv-msg-width {
                            width: 80%;
                            p {
                                background-color: $primary;
                                color: $white;
                                border-radius: $num0 8px 8px 8px;
                            }
                        }
                    }
                }
                &.sent-msg-row {
                    .sent-msg {
                        width: 80%;
                        .msg-box {
                            text-align: right;
                        }
                        p {
                            text-align: left;
                            //background: lighten($black, 92);
                            background-color: darken($white, 0);
                            color: lighten($black, 10);
                            border-radius: 8px 8px $num0 8px;
                        }
                        .chat-upload-video,
                        .chat-upload-img {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
        .chat-container-thread-detail {
            min-height: 49px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
            div {
                h5,
                span {
                    @include transition-mixin(all, 0.3, ease);
                    &:hover,
                    &:focus {
                        color: darken($white, 7%) !important;
                    }
                }
                h5 {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-right: 1rem;
                }
            }
            .chat-add-member-icon-wrap {
                height: 30px;
                width: 30px;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .chat-container-thread-type {
            margin-left: -1px;
            input {
                &:focus {
                    box-shadow: none;
                    border-color: #ced4da;
                }
            }
            .btn-send-msg {
                svg {
                    height: 21px;
                    width: 21px;
                }
            }
            .chat-media-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 4;
                right: 50px;
                height: 100%;
                display: flex;
                align-items: center;
                svg {
                    height: 28px;
                    width: 28px;
                    fill: darken($primary, 3%);
                    &:hover {
                        fill: darken($primary, 17%);
                    }
                }
            }
            .chatpreview {
                .media-preview-box {
                    padding: 0.5rem 0.75rem;
                    margin-bottom: $num0;
                    border-radius: $num0;
                }
            }
        }
    }
    .chat-container-thread-list-preview {
        min-height: calc(100% - 0px);
        max-height: calc(100% - 0px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .chat-container-private-chat-preview {
        min-height: calc(100% - 49px);
        max-height: calc(100% - 49px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .chat-container-welcome-screen {
        .chat-container-welcome-img {
            img {
                width: 181px;
                height: 181px;
                object-fit: cover;
                object-position: top center;
            }
        }
    }
}

/*Dashboard CSS Start*/
.dashboard-aside {
    .dashboard-aside-width {
        width: 275px;
        @media (max-width: 1280px) {
            width: 50px;
        }
        .dashboard-aside-nav {
            nav {
                a {
                    @include rfs(19px);
                    @extend .futura-heavy;
                    @include transition-mixin(all, 0.2, ease);
                    padding: 0.5rem 0.75rem;
                    color: $secondary;
                    &:not(:last-child) {
                        margin-bottom: 0.5rem;
                    }
                    &.active {
                        color: $primary;
                    }
                    &:hover {
                        background-color: $primary;
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.dashboard-main-width {
    max-width: 880px;
    width: 100%;
    margin: $num0 auto;
    @extend .form-bg;
    //background-color: lighten($primary, 24) ;
    /*
    border-left: 1px solid lighten($black, 92);
    @include media-breakpoint-down(md){
        width: 640px;
    }
    @include media-breakpoint-down(sm){
        width: auto;
    }*/
}

.post-user-img-lg {
    min-width: 44px;
    @include media-breakpoint-down(sm) {
        min-width: 34px;
    }
    img {
        width: 44px;
        height: 44px;
        border-radius: 100%;
        object-fit: cover;
        object-position: top center;
        background-color: $gray;
        @include media-breakpoint-down(sm) {
            width: 34px;
            height: 34px;
        }
    }
}

.post-user-img-sm {
    min-width: 36px;
    @include media-breakpoint-down(sm) {
        min-width: 30px;
    }
    img {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        object-fit: cover;
        object-position: top center;
        background-color: $gray;
        @include media-breakpoint-down(sm) {
            width: 30px;
            height: 30px;
        }
    }
}

.post-add-box {
    border-bottom: 1px solid lighten($black, 90);
    .post-add-input {
        .post-comment-input-field {
            min-height: 100px;
            height: auto;
            @include media-breakpoint-down(sm) {
                min-height: 80px;
            }
        }
    }
}
.post-result-username {
    &:hover {
        color: $btn-primary !important;
    }
}
.post-date-time {
    @include media-breakpoint-down(xs) {
        @include rfs(12px);
    }
}
.post-result-listing {
    .post-result-items {
        .post-result-items-comment {
            .post-comment-list-text {
                &,
                a {
                    @include rfs(16px);
                    line-height: 1.4;
                    @include font-family-mixin($futura-medium);
                }
            }
        }
        .post-add-input {
            .icon-upload-media {
                height: 28px;
                width: 28px;
                img {
                    width: 18px;
                    height: 18px;
                }
            }
            .post-add-input-group {
                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                }
            }
            .post-comment-input-field {
                min-height: 36px;
                height: auto;
                @include media-breakpoint-up(md) {
                    min-width: 250px;
                }
            }
        }
        .post-comment-list-textbox {
            background: lighten($black, 90);
            border-radius: $num0 12px 12px 12px;
            .post-comment-list-text {
                @include rfs(14px);
            }
        }
    }
}
.btn.btn-primary.trash-btn {
    background-color: #f36262 !important;
    border-color: #f36262;
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
    margin-left: 1rem;
    @include media-breakpoint-down(sm) {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
    }

    .icon-trash {
        height: 16px;
        width: 16px;
        @include media-breakpoint-down(sm) {
            height: 14px;
            width: 14px;
        }
    }
    &:hover {
        background-color: darken(#f36262, 0.1) !important;
        border-color: darken(#f36262, 0.1);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        transform: translateY(-1px);
    }
}

.post-user-img-lg,
.post-user-img-sm {
    a {
        position: relative;
        display: inline-block;
        &:hover::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 100%;
        }
    }
}
.icon-upload-media {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition-mixin(all, 0.3, ease);
    color: darken($primary, 10);
    color: $primary;
    background-color: $primary;
    color: $white !important;
    img {
        height: 22px;
        width: 22px;
    }
    &:hover {
        box-shadow: $num0 2px 8px rgba(0, 0, 0, 0.25);
        transform: translateY(-1px);
    }
}

.post-comment-listing {
    .post-comment-list-item-article {
        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }
}
.post-result-upload-img {
    img {
        max-width: 100%;
    }
}
.post-result-upload-video {
    iframe,
    video {
        border: 1px solid lighten($black, 92);
        max-width: 100%;
        width: 100%;
        &:focus {
            outline: none !important;
        }
    }
}
.comment-show-more-list {
    .btn-load-comment {
        @include rfs(14);
        color: $secondary;
        border-radius: $num0;
        box-shadow: none !important;
        text-transform: initial;
        padding: 0.25rem 0.5rem;
        &:hover {
            background-color: lighten($black, 92%);
        }
    }
}
.form-group {
    $heights: 36, 38, 40;
    .form-control {
        @each $x in $heights {
            &.input-#{$x}h {
                height: #{$x}px;
            }
        }
    }
}
.search-input-group {
    position: relative;
    .form-control {
        position: relative;
        z-index: 1;
        padding-left: 44px;
        padding-right: 44px;
    }
    .search-icon {
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        left: 14px;
        height: 20px;
        width: 20px;
        fill: $btn-primary;
    }
    .search-clear-icon {
        @extend .icon-clear;
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
    }
}
.media-preview-box {
    background-color: lighten($gray, 5%);
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}
.preview-image,
.preview-commentimage {
    .media-preview-box {
        max-width: 280px;
        margin-top: 1rem;
        video,
        img {
            max-width: 100%;
        }
    }
}
.btn-media-delete {
    @extend .icon-clear;
    padding: $num0;
    min-width: 26px;
    margin-left: 0.5rem;
    position: absolute;
    z-index: 2;
    top: -0.5rem;
    right: -0.625rem;
    height: 26px;
    width: 26px;
    &:hover {
        @extend .box-shadow-none;
    }
}

/*Loader CSS Strt*/
.loader-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999999955555;
    display: none;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    &.active,
    &.activepart {
        display: block;
    }
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 8px solid #f3f3f3;
        border-radius: 50%;
        border-top: 8px solid $primary;
        width: 80px;
        height: 80px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;

        margin-left: -25px;
        margin-top: -25px;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.form-group.hasdata label {
    color: #0b62ed;
    top: -0.625rem;
    font-size: 0.75rem;
}

/*Custom CheckBox & Radio button CSS Start*/
.custom-control {
    min-height: auto;
    .custom-control-input {
        z-index: 1;
        &:focus:not(:checked) ~ .custom-control-label {
            &::before {
                border-color: #adb5bd !important;
            }
        }
        &:not(:disabled) {
            &:active ~ .custom-control-label {
                &::before {
                    border-color: #adb5bd !important;
                }
            }
        }
    }
    &.custom-radio {
        .custom-control-input,
        .custom-control-label {
            @extend .cursor-point;
        }
    }
}

/*Participant Modal Listing CSS Start*/
.participant-modal-items-scollbar {
    max-height: 200px;
    overflow-y: auto;
}
.participant-modal-items {
    @include transition-mixin(all, 0.3, ease);
    min-height: 48px;

    &:not(:last-child) {
        margin-bottom: 1px;
    }
    .user-profile-image {
        min-width: 40px;
        img {
            height: 40px;
            width: 40px;
            object-fit: cover;
            object-position: top center;
        }
    }
    .user-profile-info {
        min-height: 48px;
        position: relative;
        z-index: 1;
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba($black, 0.1);
            position: absolute;
            bottom: -1px;
        }
        .btn {
            min-width: 81px !important;
        }
    }
}

/*Modal Popup CSS Start*/
.modal-header {
    background-color: darken($primary, 5%);
    color: $white;
    .close {
        color: $white;
    }
}
.modal-content {
    border: $num0;
    box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.125);
}
/*Icon size CSS*/
$iconSizes: 24;
@each $size in $iconSizes {
    .icon-#{$size} {
        width: #{$size}px;
        height: #{$size}px;
    }
}
.page-title {
    h1 {
        @include rfs(32);
        @include media-breakpoint-down(sm) {
            @include rfs(24);
        }
    }
}
