// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(
                str-slice($string, $index + str-length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face(
    $name,
    $path,
    $weight: null,
    $style: null,
    $font-display: fallback,
    $exts: eot woff2 woff ttf svg
) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_"),
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype",
    );

    @each $ext in $exts {
        $extmod: if(
            map-has-key($extmods, $ext),
            $ext + map-get($extmods, $ext),
            $ext
        );
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append(
            $src,
            url(quote($path + "." + $extmod)) format(quote($format)),
            comma
        );
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
        font-display: $font-display;
    }
}

//// ====================== END ==========

$path: "./" !default;

@include font-face(Poppins-Regular, $path + fonts/Poppins-Regular);
@include font-face(Poppins-Bold, $path + fonts/Poppins-Bold);
@include font-face(Poppins-Medium, $path + fonts/Poppins-Medium);
@include font-face(CeraPro-Bold, $path + fonts/CeraPro-Bold);
@include font-face(Mukta-Bold, $path + fonts/Mukta-Bold);
@include font-face(Mukta-Medium, $path + fonts/Mukta-Medium);
@include font-face(Futura-Book, $path + fonts/Futura-Book);
@include font-face(Futura-Bold, $path + fonts/Futura-Bold);
@include font-face(Futura-Medium, $path + fonts/Futura-Medium);
@include font-face(Futura-Heavy, $path + fonts/Futura-Heavy);
