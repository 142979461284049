/*Mixin for font size*/
@mixin font-size-mixin($size) {
    font-size: remConversion($size);
}

/*Mixin For Font Family*/
@mixin font-family-mixin($font-family-value) {
    font-family: $font-family-value;
}

/*Mixin For Margin and Padding*/
@mixin space-mixin($space-type, $area, $size) {
    @if ($area == $top) {
        @if ($space-type == $padding) {
            padding-top: $size;
        } @else if($space-type == $margin) {
            margin-top: $size;
        }
    } @else if($area == $right) {
        @if ($space-type == $padding) {
            padding-right: $size;
        } @else if($space-type == $margin) {
            margin-right: $size;
        }
    } @else if($area == $bottom) {
        @if ($space-type == $padding) {
            padding-bottom: $size;
        } @else if($space-type == $margin) {
            margin-bottom: $size;
        }
    } @else if($area == $left) {
        @if ($space-type == $padding) {
            padding-left: $size;
        } @else if($space-type == $margin) {
            margin-left: $size;
        }
    } @else {
        @if ($space-type == $padding) {
            padding: $size;
        } @else if($space-type == $margin) {
            margin: $size;
        }
    }
}

/*Mixin For Background Image*/
@mixin background-image($image-url, $image-repeat, $image-position) {
    background-image: url($image-url);
    background-repeat: $image-repeat;
    background-position: $image-position;
}

/*Mixin For border*/
@mixin border-mixin($size, $position, $type, $color) {
    @if ($position == $bottom) {
        border-bottom: #{$size}px $type #{$color};
    } @else if($position == $top) {
        border-top: #{$size}px $type #{$color};
    } @else if($position == $right) {
        border-right: #{$size}px $type #{$color};
    } @else if($position == $left) {
        border-left: #{$size}px $type #{$color};
    } @else {
        border: #{$size}px $type #{$color};
    }
}

/*Mixin For Transition*/
@mixin transition-mixin($animation-elem, $animation-sec, $animation-type) {
    transition: $animation-elem #{$animation-sec}s $animation-type;
    -webkit-transition: $animation-elem #{$animation-sec}s $animation-type;
    -moz-transition: $animation-elem #{$animation-sec}s $animation-type;
}
