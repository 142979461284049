a {
    color: $primary;
}
a:hover,
a:focus {
    color: lighten($secondary, 20);
    outline: none;
}
a.text-primary:hover,
a.text-primary:focus {
    text-decoration: underline !important;
    color: $primary !important;
}
ul {
    margin: 0;
    padding: 0;
}
ul,
li {
    list-style-type: none;
}
body {
    font-size: $body-font-size;
    font-family: $body-font;
    color: $secondary;
    background-color: $white;
    line-height: 1.4;
    @media (min-width: 1600px) {
        line-height: 1.7;
    }
}

.text-black {
    color: $black;
}
.text-gray {
    color: $gray;
}
iframe {
    max-width: 100%;
}
button:focus {
    outline: #{$num0}px !important;
}
.cursor-point {
    cursor: pointer;
}
.mn-w-auto {
    min-width: auto;
}
.border-radius-none {
    border-radius: $num0 !important;
}
.box-shadow-none {
    box-shadow: none !important;
}
.mx-w1920 {
    max-width: 1920px;
}
.mn-h600 {
    min-height: 600px;
}
.border-radius-5 {
    border-radius: 5px;
}
.text-initial {
    text-transform: initial;
}
.pattern {
    @include background-image("./pattern.png", $repeat, $left $top);
    background-attachment: fixed;
}
.pattern-white {
    @include background-image("./pattern-white.png", $repeat, $left $top);
    background-attachment: fixed;
}
.form-bg {
    background-color: rgba(#e9e9e9, 0.37);
    border-radius: 5px;
}
.icon-right-angle {
    display: inline-block;
    border: solid $primary;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(-45deg);
}
.word-break {
    word-wrap: break-word;
    word-break: break-word;
}
textarea.resize-none {
    resize: none;
}
.mn-w-0 {
    min-width: $num0;
}
.text-primary-dark {
    color: darken($primary, 5%);
    &:hover {
        color: darken($primary, 7%);
    }
}

.btn {
    @extend .futura-heavy;
    color: $primary;
    border-radius: 28px;
    text-transform: capitalize;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include transition-mixin(all, 0.3, ease);
    font-size: rfs-value(1rem);
    &:hover {
        box-shadow: 0 10px 10px -7px rgba(0, 0, 0, 0.6);
    }

    &.btn-primary {
        background-color: $btn-primary !important;
        border-color: $btn-primary;
        color: $white !important;
        &:hover {
            background-color: darken($primary, 10%) !important;
        }
    }
    &.btn-outline-primary {
        &:hover {
            color: $white !important;
        }
    }

    &.btn-lg {
        min-width: 165px;
        min-height: 50px;
        @include media-breakpoint-down(md) {
            min-width: 135px;
            min-height: 40px;
        }
    }
    &.btn-sm {
        min-width: 122px;
        min-height: 42px;

        @include media-breakpoint-down(xxl) {
            min-width: 117px;
            min-height: 40px;
        }
    }
    &.btn-xs {
        min-height: 27px;
        @include rfs(12px);
        @extend .lh-12;
    }
}

/*Popup Cursor CSS Code*/
.mfp-zoom-out-cur {
    cursor: default !important;
}
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer !important;
}

.ratio {
    img {
        object-fit: cover;
    }
}
picture {
    display: flex;
}

/*Popup Gallery CSS Code*/
.popup-gallery {
    .video {
        position: relative;
        display: block;
        background: $black;
        img {
            opacity: 0.5;
        }
        @include caret(right);
        &::after {
            position: absolute;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
            font-size: 3rem;
        }
    }
}

/*Accordion CSS Code*/
.accordion {
    a {
        &[aria-expanded="false"] {
            @include caret;
            &::after {
                position: absolute;
                right: 1rem;
            }
        }
        &[aria-expanded="true"] {
            @include caret(up);
            &::after {
                position: absolute;
                right: 1rem;
            }
        }
    }
}
/*Blog CSS Code*/
.blogbuttons {
    &::after,
    &::before {
        position: absolute;
        top: 0.85rem;
    }

    &_nxt {
        position: relative;
        @include caret(right);
        &::after {
            right: 0.5rem;
        }
    }
    &_pre {
        position: relative;
        @include caret(left);
        &::before {
            left: 0.5rem;
        }
    }
}

/*Form Elements Reset CSS Start*/
.form-group {
    margin-bottom: 1em;
    label {
        @extend .font-15;
        @extend .futura-medium;
    }
    .form-control {
        background-color: rgba($primary, 0.2);
        border: $num0;
        height: 48px;
        @extend .font-15;
        color: rgba($secondary, 0.8);
        @extend .futura-medium;
    }
    textarea.form-control {
        min-height: 80px;
    }
}
#frmsearch .form-control {
    background-color: $white;
}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $primary;
    background-color: $primary;
}
.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: $body-font !important;
}
.custom-select option,
.was-validated .custom-select:valid option,
.custom-select.is-valid option {
    font-family: Arial, Helvetica, sans-serif;
}
.custompicker {
    .bootstrap-select {
        .btn.dropdown-toggle {
            border-radius: 0.25rem;
            @extend .box-shadow-none;
            background-color: transparent !important;
            height: 48px;
            border: 0 !important;
            @extend .font-15;
            @extend .futura-medium;
            text-transform: initial;
            color: rgba($secondary, 0.8);
            &:focus {
                outline: 0 !important;
                box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25) !important;
            }
            .filter-option {
                display: inline-flex;
                align-items: center;
            }
        }
        &.show {
            .btn.dropdown-toggle {
                outline: 0 !important;
                box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25) !important;
            }
        }
    }
    .dropdown-menu {
        z-index: 9999;
        min-width: auto !important;
        width: 100%;
        .dropdown-item {
            padding: 0.25rem 1rem;
            @extend .font-14;
            white-space: normal;
            &:hover,
            &:focus,
            &.active,
            &:active {
                background-color: $primary;
                color: $white;
            }
        }
    }
    &.multipicker {
        .dropdown-menu {
            min-width: auto !important;
            width: 100%;
            div.inner {
                max-height: 300px !important; /*max-width: 300px;*/
            }
        }
    }
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
    color: rgba(0, 0, 0, 0.6) !important;
}
