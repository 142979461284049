/*Common Variables & Maps*/
$padding: padding;
$margin: margin;
$x: x;
$y: y;
$xy: xy;
$all: all;
$top: top;
$bottom: bottom;
$left: left;
$right: right;
$center: center;
$repeat: repeat;
$no-repeat: no-repeat;
$repeat-x: repeat-x;
$repeat-y: repeat-y;
$direction: top, left, right, bottom;

/*Color Variables*/
//$primary-color: #FE0000;
//$secondary-color: #000000;
$primary: #99c4e0;
$secondary: #0b1b1a;
$btn-primary: #86b7d1;
$white: #FFFFFF;
$black: #000000;
$gray: #C5C5C5;

/*Fonts Variables*/
$poppins-regular: "Poppins-Regular";
$poppins-bold: "Poppins-Bold";
$poppins-medium: "Poppins-Medium";
$cerapro-bold: "CeraPro-Bold";
$mukta-bold: "Mukta-Bold";
$mukta-medium: "Mukta-Medium";
$futura-book: "Futura-Book";
$futura-bold: "Futura-Bold";
$futura-medium: "Futura-Medium";
$futura-heavy: "Futura-Heavy";

$fontsFamily: ($poppins-regular, $poppins-bold, $poppins-medium, $mukta-bold, $mukta-medium, $futura-book, $futura-bold, $futura-medium, $cerapro-bold, $futura-heavy);
$body-font: $futura-book;
$heading-font: $futura-bold;

/*Font Sizes Variables and Map(Array)*/
$font-sizes-map: 50, 40, 30, 20, 18, 16, 15, 14, 10, 35, 24, 13, 12, 45;
$body-font-size: 0.975rem;

$h1: nth($font-sizes-map,1);
$h2: nth($font-sizes-map,2);
$h3: nth($font-sizes-map,3);
$heading-tag-font-map: (
    'font':(
        'size': ($h1, $h2,$h3)
    ),
);

/*Number Variables*/
$num0: 0;
$num1-10: 1,2,3,4,5,6,7,8,9,10;
$num100-900: 100, 200, 300, 400, 500, 600, 700, 800, 900;

/*Customize Bootstrap Container width and Breakpoints*/
$grid-breakpoints:(
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px,
xxxl: 1600px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px,
  xxxl: 1540px,
);

$rfs-breakpoint: 1600px !default;
$enable-responsive-font-sizes: true;

/*Background color*/
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "primary-btn":  $btn-primary,
  "gray":  $gray,
  "white": $white
);


